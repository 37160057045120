<template>
  <bg-modal :title="modalTitle" :value="isOpen" @close="closeModal">
    <ValidationObserver ref="observer">
      <ValidationProvider
        name="Nama Biaya"
        rules="required|max:50"
        v-slot="{ errors }"
      >
        <bg-field
          label="Nama Biaya"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input
            v-model="cost.name"
            :disabled="isInvoiceSource"
            placeholder="Masukkan nama biaya"
          />
        </bg-field>
      </ValidationProvider>

      <ValidationProvider
        name="Jumlah Biaya"
        rules="required|numeric|min_value:1|max_value:5000000"
        v-slot="{ errors }"
      >
        <bg-field
          label="Jumlah Biaya"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input
            v-model="cost.amount"
            :disabled="isInvoiceSource"
            prefix="Rp"
          />
        </bg-field>
      </ValidationProvider>

      <ValidationProvider
        name="Transfer ke Pemilik"
        :rules="`required|numeric|min_value:0|max_value:${maxDisburseAmount}`"
        v-slot="{ errors }"
      >
        <bg-field
          label="Transfer ke Pemilik"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-input v-model="cost.disburse_amount" prefix="Rp" />
        </bg-field>
      </ValidationProvider>
    </ValidationObserver>

    <bg-text>Asal Biaya</bg-text>
    <bg-text size="body-2">{{ source | capitalize }}</bg-text>

    <template #footer>
      <div class="flex align-center justify-end">
        <bg-button class="mr-16" :disabled="loading" @click="closeModal"
          >Kembali</bg-button
        >
        <bg-button variant="primary" :loading="loading" @click="handleSave"
          >Simpan</bg-button
        >
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgInput, BgModal, BgButton, BgField, BgText } from 'bangul-vue';
import detailTransferApi from '@admin_endpoints/detail-transfer';
import { capitalize } from 'Utils/typography';

export default {
  name: 'CostModal',

  components: {
    BgInput,
    BgModal,
    BgButton,
    BgField,
    BgText,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    costData: {
      type: Object,
      default: () => ({}),
    },
  },

  filters: { capitalize },

  data() {
    return {
      loading: false,
      cost: {
        name: '',
        amount: null,
        disburse_amount: null,
      },
    };
  },

  computed: {
    isEditSection() {
      return !!Object.keys(this.costData).length;
    },
    modalTitle() {
      return this.isEditSection ? 'Ubah Biaya Penyewa' : 'Tambah Biaya Penyewa';
    },
    approvalId() {
      return this.$route.params.approval_id;
    },
    invoiceId() {
      return this.$route.params.disbursement_invoice_id;
    },
    maxDisburseAmount() {
      return this.cost.amount || 0;
    },
    source() {
      return this.costData.source || 'manual';
    },
    isInvoiceSource() {
      return this.source === 'invoice';
    },
  },

  watch: {
    costData(cost) {
      if (Object.keys(cost).length) {
        this.cost.name = cost.name;
        this.cost.amount = this.clearNumber(cost.amount);
        this.cost.disburse_amount = this.clearNumber(cost.disburse_amount);
      }
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.$emit('update:costData', {});
      this.cost = {
        name: '',
        amount: null,
        disburse_amount: null,
      };
    },

    async handleSave() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        if (this.isEditSection) {
          this.updateCost();
        } else {
          this.addCost();
        }
      }
    },

    async addCost() {
      this.loading = true;

      try {
        await detailTransferApi.storeAdditionalCost(
          this.approvalId,
          this.invoiceId,
          this.cost
        );

        this.$emit('action-success');
        this.$toast.show('Biaya tambahan penyewa berhasil ditambahkan.');
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.show(
          'Terjadi kesalahan saat membuat biaya tambahan penyewa.'
        );
      } finally {
        this.loading = false;
      }
    },

    async updateCost() {
      if (!this.costData.id) return;

      this.loading = true;

      const data = { ...this.cost };

      if (this.isInvoiceSource) {
        delete data.name;
        delete data.amount;
      }

      try {
        await detailTransferApi.updateAdditionalCost(
          this.approvalId,
          this.invoiceId,
          this.costData.id,
          data
        );

        this.$emit('action-success');
        this.$toast.show('Perubahan berhasil disimpan.');
        this.closeModal();
      } catch (error) {
        console.error(error);
        this.$toast.show('Terjadi kesalahan saat menyimpan.');
      } finally {
        this.loading = false;
      }
    },

    clearNumber(value) {
      if (!value) return null;

      return Number(String(value).replace(/\..+/, ''));
    },
  },
};
</script>
